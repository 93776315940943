<template>
  <div id="product">
    <div class="hadheight"></div>
    <Header />
    <div class="weeb_load basefont40" style="padding: 1.6rem 0"  v-if="error==0">加载中....</div>
    <template v-else-if="error==1">
      <div class="productbox-sec1">
          <router-link :to="{name: 'Productlist',params:{pan: 1,id: item.id,title: item.name}}" class="item" v-for="(item,index) in productdata" :key="index">
              <div class="img">
                  <img :src="item.backgroundImg" :alt="item.name">
              </div>
              <div class="font">
                  <div class="tit basefont36">
                      <span>{{item.name}}</span>
                  </div>
              </div>
          </router-link>
      </div>
    </template>
    <div class="weeb_load basefont40" style="padding: 1.6rem 0"  v-else>没有内容</div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: "Product",
  components: {
    Header,
    Footer
  },
  data(){
    return {
      error: 0,
      productdata:[
        //   {
        //       id: 1,
        //       url: require("../../assets/images/productimgf1.jpg"),
        //       title: "轮毂"
        //   },
        //   {
        //       id: 2,
        //       url: require("../../assets/images/productimgf2.jpg"),
        //       title: "轮毂"
        //   },
        //   {
        //       id: 3,
        //       url: require("../../assets/images/productimgf3.jpg"),
        //       title: "轮毂"
        //   },
        //   {
        //       id: 4,
        //       url: require("../../assets/images/productimgf4.jpg"),
        //       title: "轮毂"
        //   },
        //   {
        //       id: 5,
        //       url: require("../../assets/images/productimgf5.jpg"),
        //       title: "轮毂"
        //   },
        //   {
        //       id: 6,
        //       url: require("../../assets/images/productimgf6.jpg"),
        //       title: "轮毂"
        //   },
        //   {
        //       id: 7,
        //       url: require("../../assets/images/productimgf7.jpg"),
        //       title: "轮毂"
        //   },
        //   {
        //       id: 8,
        //       url: require("../../assets/images/productimgf8.jpg"),
        //       title: "轮毂"
        //   }
      ]
    }
  },
  created:function () {
     
  },
  mounted:function () {
    this.$axios.post("/api2/portal/product/category/list",
    {
        current: 1,
        size: 8
    }).then(res => {
        if(res.code=="000000"){
          if(res.data.data.length){
            this.error = 1;
            this.productdata=res.data.data;
          }else {
              this.error = 2;
          }
        }else{
          this.error = 2;
        }
        
    }).catch(error => {
        console.log(error);
    });
  },
  watch:{
    
  },
  methods: {
    
  }
}
</script>
